import request from '@/utils/request'

export function listPage(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/stockListPage',
        params
    })
}

// 查询初始化
export function pageInit() {
    return request({
        method:'get',
        url:'/api/amazon/asin/pageInit',
    });
}

//查看权重
export function detailWeight(id) {
    return request({
        url:`/api/amazon/asin/${id}`,
        method:'get'
    });
}

//新增更改权重
export function addWeight(data) {
    return request({
        url:'/api/amazon/asin/addWeight',
        method:'post',
        data
    });
}